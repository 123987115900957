<template>
    <div>
        <!-- Field Name -->
        <validation-observer ref="personalInfoForm">
        <b-card>
            <!-- form -->
            <b-form>
                <b-row>
                    <!-- Name -->
                    <b-col md="3">
                        <b-form-group label="Field Name">
                        <validation-provider
                            #default="{ errors }"
                            name="field name"
                            rules="required"
                        >
                            <b-form-input
                                v-model="fieldName"
                                :state="errors.length > 0 ? false:null"
                                placeholder="Enter Text"
                                @blur="fieldName=fieldName.trim()"
                            />
                            <small class="text-danger" v-if="!fieldAlreadyExists">{{ errors[0] }}</small>
                            <small class="text-danger" v-else>Field name alredy exists.</small>
                        </validation-provider>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-form>
        </b-card>

        <!-- Courses -->
        <b-card>
            <!-- form -->
            <b-form>
                <b-table
                 responsive :items="courses" 
                 :fields="tableColumns" 
                 primary-key="id"
                 show-empty
                 empty-text="No matching records found"
                 class="cfc_table_wrapper position-relative">

                    <!-- Column : Course Name -->
                    <template #cell(course_name)="data">
                        <validation-provider
                            #default="{ errors }"
                            name="course name"
                            :vid="'course name'+data.index"
                            rules="required"
                        >
                            <b-form-input
                                v-model="data.item.name"
                                :state="errors.length > 0 ? false:null"
                                placeholder="Enter Text"
                                style="width:492px"
                                @blur="data.item.name=data.item.name.trim()"
                            />
                            <small class="text-danger" v-if="!alreadyExists(data.item.name,data.index)">{{ errors[0] }}</small>
                            <small class="text-danger" v-else>Course already exists.</small>
                        </validation-provider>
                    </template>

                    <!-- Column : Action -->
                    <template #cell(action)="data">
                        <div style="white-space: nowrap" v-if="$route.meta.action=='write' || $route.meta.action==undefined">
                            <img src='@/assets/images/erflog/Delete.png' style="cursor:pointer" @click.prevent="deleteRow(data.index)" class="action_icon"/>
                        </div>
                        <div v-else>
                          --
                        </div>
                    </template>
                </b-table>
                <div class="add_edu_link">
                    <a @click.prevent="addRow()">Add New Course</a>
                </div>
            </b-form>
        </b-card>

        </validation-observer>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard,BFormInput, BFormGroup, BForm, BRow, BCol, BButton,BTable
} from 'bootstrap-vue'
import firebase from "@/utils/firebaseInit.js";
import firebaseTimeStamp from "firebase";
import { extend } from 'vee-validate';
import { required, email,max,max_value,integer,numeric,min,min_value,alpha_spaces,double } from 'vee-validate/dist/rules';

extend('required',required)
extend('max',max)
extend('min',min)
extend('min_value',min_value)
extend('email',email)
extend('alpha_spaces',alpha_spaces)
extend('max_value',max_value)
extend('integer',integer)
extend('numeric',numeric)
extend('double',double)

import { dbCollections } from "@/utils/firebaseCollection.js";
import { uploadImageFile } from '@/utils/FirebaseQueries/storageQuery/storageQueries'
import { ValidationFunction } from '@/utils/globalValidations.js';

import vSelect from 'vue-select'

const db = firebase.firestore()

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        BCard,
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BButton,
        BTable,
        vSelect,
    },
    data() {
        return {
            fieldName:'',
            courses:[{
                name:'',
                id:'',
                isCoursesDeleted: false
            }],
            tableColumns:['course_name','action'],
            fields:[]
        }
    },
    created() {
        var self = this

        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        var resultStr = ""
        for ( var i = 0; i < 10; i++ ) {
            resultStr += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        self.courses[0].id = resultStr

        self.$root.$on('saveNewFieldCorse',()=>{
            console.log('save called')
            if(self.$refs.personalInfoForm)
            {
                self.saveFieldCourse()
            }
        })
        self.$root.$on('cancelNewFieldCourse',()=>{
            console.log('close called')
            if(self.$refs.personalInfoForm)
            {
                self.$refs.personalInfoForm.reset()
                self.$router.push({name:'field-course-mgmt'})
            }
        })

        //get All Fields
        db
            .collection(dbCollections.FIELDANDCOURSE)
            .orderBy('createdAt')
            .get()
            .then((querySnapshot)=>{
                querySnapshot.forEach(doc=>{
                    // if(!doc.data().isDeleted)
                    // {
                        self.fields.push(doc.data().fieldName)
                    // }
                })
            })
            .catch(error=>{
                console.log(error)
            })
    },
    methods: {
        alreadyExists(name,ind)
        {
            var self = this
            var found = false
            self.courses.forEach((names,index)=>{
                if(names.name.toLowerCase().trim()==name.toLowerCase().trim() && ind != index &&  ind>=index)
                {
                    found = true
                    // console.log("Already Existing.")
                }
            })
            return found
        },
        addRow()
        {
            this.$refs.personalInfoForm.validate().then(success=>{
                if(success && !this.duplicateCourseName)
                {
                    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                    var charactersLength = characters.length;
                    var resultStr = ""
                    for ( var i = 0; i < 10; i++ ) {
                        resultStr += characters.charAt(Math.floor(Math.random() * charactersLength));
                    }
                    // console.log("Id",resultStr)
                    this.courses.push({name:'',id:resultStr,isCoursesDeleted:false})
                }
            })
        },
        deleteRow(index)
        {
            this.courses.splice(index,1);
        },
        saveFieldCourse() {
            var self = this
            console.log("Validate",self.$refs.personalInfoForm)
            self.$refs.personalInfoForm.validate().then(success => {
                if (success && !self.duplicateCourseName && !self.fieldAlreadyExists) {

                    console.log("Course",self.courses)

                    db
                        .collection(dbCollections.FIELDANDCOURSE)
                        .add({
                            createdAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                            updatedAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                            isDeleted:false,
                            id:"",

                            fieldName:self.fieldName,
                            courses:self.courses
                        })
                        .then((docRef)=>{
                            db
                                .collection(dbCollections.FIELDANDCOURSE)
                                .doc(docRef.id)
                                .update({
                                    id:docRef.id
                                })
                                .then(() =>{
                                    self.$router.push({name:'field-course-mgmt'})
                                    self.$root.$emit('showToastMessage','Field and courses added successfully.','success')
                                    self.$root.$emit('stopSpinner')
                                })
                                .catch(error=>{
                                    console.log(error)
                                    self.$root.$emit('showToastMessage',error.message,'danger')
                                    self.$root.$emit('stopSpinner')
                                })
                        })
                        .catch(error=>{
                            console.log(error)
                            self.$root.$emit('showToastMessage',error.message,'danger')
                            self.$root.$emit('stopSpinner')
                        })
                }
                else
                {
                    console.log("Success",success)
                    self.$root.$emit('stopSpinner')
                }
            })
        },
    },
    computed:{
        duplicateCourseName()
        {
            var self = this

            var duplicate = false

            for (let i = 0; i < self.courses.length; i++) {
                for (let j = i+1; j < self.courses.length; j++) {
                    if(self.courses[i].name.toLowerCase()==self.courses[j].name.toLowerCase())
                    {
                        duplicate = true
                    }
                }
            }

            return duplicate
        },
        fieldAlreadyExists()
        {
            var self = this

            var duplicate = false

            self.fields.forEach(data=>{
                if(data.toLowerCase()==self.fieldName.toLowerCase())
                {
                    duplicate = true
                }
            })
            return duplicate;
        }
    }
}
</script>
<style scoped>
.table-responsive.cfc_table_wrapper {
    padding: 0px!important;
}
</style>